import Api from '../../shared/services/api'
import axios from '@/plugins/axios.js'
import Utils from '@/plugins/utils';


const baseUrl = "shipments";
export default {

    get() {
        return Api().get(baseUrl);
    },
    getById(id) {
        return Api().get(baseUrl + `/${id}`);
    },
    print(id) {
        return Api().get(baseUrl + `/${id}/print`);
    },
    typeHead(searchTerm, all) {
        return Api().get(`${baseUrl}/typeHead?${searchTerm}&all=${all}`);
    },
    getByTrackingNumber(TrackingNumber) {
        return Api().get(baseUrl + `/delivery/clients?TrackingNumber=${TrackingNumber}`);
    },
    getByTrackingNumberr(TrackingNumber) {
        return Api().get(`${baseUrl}/TrackingNumber?trackingNumber=${TrackingNumber}`);
    },
    query(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + "?" + qParams.toString());
    },
    clientDelivery(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + "/delivery/clients?" + qParams.toString());
    },
    receiverCall(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + "/delivery/receivercall?" + qParams.toString());
    },
    reports(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + "/Report?" + qParams.toString());
    },
    adminReports(options) {
        const qParams = new URLSearchParams(options);
        return Api().post(baseUrl + "/ActivityLine?" + qParams.toString());
    },
    edit(entity) {
        if (entity.id)
            return Api().put(baseUrl + `/${entity.id}`, entity);
        else
            return this.add(entity);
    },
    add(entity) {
        return Api().post(baseUrl, entity);
    },
    bulk(collection) {
        return Api().post(baseUrl + `/bulk`, collection);
    },
    delete(id) {
        return Api().delete(baseUrl + `/${id}`);
    },
    cancelDelivery(id) {
        return Api().put(baseUrl + `/${id}/CancelDelivery`);
    },
    duePrice(id, value) {
        return Api().put(baseUrl + `/${id}/DuePrice?value=${value}`);
    },
    otherServicesPrice(id, value) {
        return Api().put(baseUrl + `/${id}/OtherServicesPrice?value=${value}`);
    },
    updateTaxNum(trackingNumber, taxNum) {
        return Api().put(baseUrl + `/${trackingNumber}/UpdateTaxNum?taxNum=${taxNum}`);
    },
    updatePaidType(trackingNumber, PaidType) {
        return Api().put(baseUrl + `/${trackingNumber}/UpdatePaidType?paidType=${PaidType}`);
    },
    whatsAppSent(id) {
        return Api().get(baseUrl + `/WhatsAppSent/${id}`);
    },
    byTripId(id) {
        return Api().get(baseUrl + `/trip/${id}`);
    },
    byClientId(id) {
        return Api().get(baseUrl + `/client/${id}`);
    },
    byDistributorId(model) {
        return Api().post(baseUrl + `/distributor`, model);
    },
    clientTop50() {
        return Api().get(baseUrl + `/client/Top50`);
    },
    bySalesmanId(id, options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + `/salesman/${id}` + "?" + qParams.toString());
    },
    salesmanHistory(id) {
        return Api().get(baseUrl + `/salesman/${id}/History`);
    },
    salesmanTop50() {
        return Api().get(baseUrl + `/salesman/Top50`);
    },
    distributorsBalance(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + `/distributorsBalance` + "?" + qParams.toString());
    },
    empShipmentsCount(options) {
        const qParams = new URLSearchParams(options);
        return Api().get(baseUrl + `/empShipmentsCount` + "?" + qParams.toString());
    },
    byExternalShipperId(id) {
        return Api().get(baseUrl + `/externalShipper/${id}`);
    },
    addToSendSMS(model) {
        return Api().post(baseUrl + `/SMSCitySent`, model);
    },
    addToCallSent(id) {
        return Api().put(baseUrl + `/${id}/ReciverCallSent`);
    },
    addToDistributor(model) {
        return Api().post(baseUrl + `/AddToDistributor`, model);
    },
    receiveFromDistributor(model) {
        return Api().post(baseUrl + `/ReceiveFromDistributor`, model);
    },
    addToRecipient(model) {
        return Api().post(baseUrl + `/AddToRecipient`, model);
    },
    changeBookTrip(model) {
        return Api().post(baseUrl + `/ChangeBookTrip`, model);
    },
    addToExternalShipper(model) {
        axios.headers = {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        };
        var form = new FormData();
        form.append("amount", model.amount);
        form.append("cityId", model.cityId);
        form.append("shipmentId", model.shipmentId);
        form.append("externalShipperId", model.externalShipperId);
        form.append("notice", model.notice);
        if (model.image)
            form.append("image", model.image, model.image.name);
        return axios.post(baseUrl + `/AddToExternalShipper`, form);
    },
    parseFloat(num) {
        if (!num)
            return 0
        else return num;
    },
    getDefaultnoline() {
        return {
            paymentType: 0,
            date: Utils.toDate(Date.now()),
            senderInfo: {},
            receiverInfo: {},
            paymentType: 2,
            receiverId: null,
            senderId: null,
            manualNo: null,
            bookNum: null,
            senderBranch: {},
            receiverBranch: {},
            receiver: {},
            sender: {},
            serviceTypeId: 6,
            items: [{
                unitId: 1,
                quantity: 1,
                size: 2,
                total: null,
                addedValueTax: null,
                includeTax: true
            }],
            netPrice: null,
            totalPrice: null,
            notice: "",
            totalPrice: null,
            addedValueTax: null,
            netPrice: null,
            insurance: null,
            otherServicesPrice: null,
            deliveryPrice: null,
            duePrice: null,
            salesman: {},
            salesmanId: null,
            paymentPaidType: false,
        };
    },
    getDefault() {
        return {
            date: Utils.toDate(Date.now()),
            senderInfo: {},
            receiverInfo: {},
            paymentType: 2,
            receiverId: null,
            senderId: null,
            manualNo: null,
            bookNum: null,
            senderBranch: {},
            receiverBranch: {},
            receiver: {},
            sender: {},
            serviceTypeId: 6,
            items: [{
                unitId: 1,
                quantity: 1,
                size: 2,
                total: null,
                addedValueTax: null,
                includeTax: true
            }],
            netPrice: null,
            totalPrice: null,
            notice: "",
            totalPrice: null,
            addedValueTax: null,
            netPrice: null,
            insurance: null,
            otherServicesPrice: null,
            deliveryPrice: null,
            duePrice: null,
            salesman: {},
            line: {},
            lineId: {},
            salesmanId: null,
            paymentPaidType: false,
        };
    },
    newShipment(shipment) {
        var newShipment = { ...shipment };
        newShipment.addedValueTax = this.parseFloat(shipment.addedValueTax);
        newShipment.insurance = this.parseFloat(shipment.insurance);
        newShipment.otherServicesPrice = this.parseFloat(shipment.otherServicesPrice);
        newShipment.totalPrice = this.parseFloat(shipment.totalPrice);
        newShipment.netPrice = this.parseFloat(shipment.netPrice);
        newShipment.duePrice = this.parseFloat(shipment.duePrice);
        newShipment.deliveryPrice = this.parseFloat(shipment.deliveryPrice);

        newShipment.tripId = newShipment.trip?.id;
        newShipment.senderId = shipment.senderInfo?.id;
        newShipment.senderBranchId = shipment.senderBranch?.id;
        newShipment.senderInfo.branch = shipment.senderBranch?.name;

        newShipment.receiverId = shipment.receiverInfo?.id;
        newShipment.receiverBranchId = shipment.receiverBranch?.id;
        newShipment.receiverInfo.branch = shipment.receiverBranch?.name;
        newShipment.items.forEach(item => {
            item.price = this.parseFloat(item.price);
            item.quantity = this.parseFloat(item.quantity);
            item.addedValueTax = this.parseFloat(item.addedValueTax);
        });
        return newShipment;
    },
    setForEdit(shipment) {

        shipment.lineId = shipment.trip?.lineId
        shipment.line = shipment.trip?.line
        shipment.tripmNum = shipment.trip?.number;
        shipment.date = Utils.toDate(shipment.date);
        shipment.bookNum = shipment.book?.manualNo
        shipment.bookId = shipment.book?.id

        return shipment;
    }
}